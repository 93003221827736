import { CurrentCompanyAndTeamDto, CurrentCompanyDto, FeatureAvailabilityDto, FeatureFlagDto, GetCompanyDto } from "@api";

import { isFeatureAvailable, isFeatureTeased } from "~plan-shared";
import { Features } from "~shared/enums";

export const isFeatureEnabled = (featureName: string, features: FeatureFlagDto[]) =>
    features.find(f => f.name === featureName)?.state ?? false;

declare type CompanyInput = CurrentCompanyAndTeamDto | CurrentCompanyDto | GetCompanyDto | null | undefined;

const isFeatureEnabledInternal = (
    ct: CompanyInput,
    isEnabled: (ct: CurrentCompanyDto | GetCompanyDto) => boolean,
) => !!ct && isEnabled("company" in ct ? ct.company : ct);

const buildFeatureCheck = (isEnabled: (ct: CurrentCompanyDto | GetCompanyDto) => boolean): ((ct: CompanyInput) => boolean) =>
    (ct: CompanyInput) => isFeatureEnabledInternal(ct, isEnabled);

const buildPlanFeatureEnabled = (availability: keyof FeatureAvailabilityDto) =>
    buildFeatureCheck(company => isFeatureAvailable(company.planTier, availability));

const buildPlanFeatureTeased = (availability: keyof FeatureAvailabilityDto) =>
    buildFeatureCheck(company => isFeatureTeased(company.planTier, availability));

const sanitiseFlags = (flags: Features | Features[]) => Array.isArray(flags) ? flags : [flags];

const buildFeatureFlagEnabled = (flags: Features | Features[]) =>
    buildFeatureCheck(company => sanitiseFlags(flags).every(flag => isFeatureEnabled(flag, company.features)));

const buildFeatureEnabled = (availability: keyof FeatureAvailabilityDto, flags: Features | Features[]) =>
    buildFeatureCheck(company =>
        isFeatureAvailable(company.planTier, availability) &&
        sanitiseFlags(flags).every(flag => isFeatureEnabled(flag, company.features)));

const buildFeatureTeased = (availability: keyof FeatureAvailabilityDto, flags: Features | Features[]) =>
    buildFeatureCheck(company =>
        isFeatureTeased(company.planTier, availability) &&
        sanitiseFlags(flags).every(flag => isFeatureEnabled(flag, company.features)));

// #region Plan Features

export const isCompanyLogoEnabled = buildPlanFeatureEnabled("companyLogo");
export const isCompanyLogoTeased = buildPlanFeatureTeased("companyLogo");

export const isPublicHolidaysEnabled = buildPlanFeatureEnabled("publicHolidays");

export const isAnnualPlanningEnabled = buildPlanFeatureEnabled("annualPlanning");
export const isAnnualPlanningTeased = buildPlanFeatureTeased("annualPlanning");

export const isFlexibleSchedulingEnabled = buildPlanFeatureEnabled("flexibleScheduling");
export const isFlexibleSchedulingTeased = buildPlanFeatureTeased("flexibleScheduling");

export const isCrossTeamFeaturesEnabled = buildPlanFeatureEnabled("crossTeamFeatures");
export const isCrossTeamFeaturesTeased = buildPlanFeatureTeased("crossTeamFeatures");

export const isCompanyPerformanceEnabled = buildPlanFeatureEnabled("companyPerformance");
export const isCompanyPerformanceTeased = buildPlanFeatureTeased("companyPerformance");

export const isEnterprisePerformanceEnabled = buildPlanFeatureEnabled("enterprisePerformance");

export const isAdHocMeetingsEnabled = buildPlanFeatureEnabled("adHocMeetings");
export const isAdHocMeetingsTeased = buildPlanFeatureTeased("adHocMeetings");

export const isNumberChartWidgetsTeased = buildPlanFeatureTeased("numberChartWidgets");
export const isEnterpriseWidgetsTeased = buildPlanFeatureTeased("enterpriseWidgets");

export const isLineManagerNotificationsEnabled = buildPlanFeatureEnabled("lineManagerNotifications");

export const isExternalIntegrationsEnabled = buildPlanFeatureEnabled("externalIntegrations");

export const isExecutionScoreTargetEnabled = buildPlanFeatureEnabled("executionScoreTarget");

export const isNumberApiEnabled = buildPlanFeatureEnabled("numberApi");

// #endregion

// #region Feature Flags

export const isPartnerPlanSetupEnabled = buildFeatureFlagEnabled(Features.partnerPlanSetup);

export const isGlobalCreateEnabled = buildFeatureFlagEnabled(Features.globalCreate);

export const isReportsEnabled = buildFeatureFlagEnabled(Features.reports);

export const isNewsEnabled = buildFeatureFlagEnabled(Features.news);

export const isUserPlanChangeEnabled = buildFeatureFlagEnabled(Features.userPlanChange);

export const isStatusNotesEnabled = buildFeatureFlagEnabled(Features.negativeStatusNotes);

export const isCustomGoalStatusesEnabled = buildFeatureFlagEnabled(Features.customGoalStatuses);

export const isGoalCancellationEnabled = buildFeatureFlagEnabled(Features.goalCancellation);

export const isPlanningSuggestionsEnabled = buildFeatureFlagEnabled(Features.planningSuggestions);

export const isSuperAdminPlanningSuggestionsEnabled = buildFeatureFlagEnabled(Features.superAdminPlanningSuggestions);

export const isDiscussionSuggestionsEnabled = buildFeatureFlagEnabled(Features.discussionSuggestions);

export const isSuperAdminDiscussionSuggestionsEnabled = buildFeatureFlagEnabled(Features.superAdminDiscussionSuggestions);

export const isWorkInstructionEnabled = buildFeatureFlagEnabled([Features.workInstruction, Features.negativeStatusNotes]);

// #endregion

// #region Compound Features

export const isRecurringActionsEnabled = buildFeatureEnabled("recurringActions", Features.recurringActions);
export const isRecurringActionsTeased = buildFeatureTeased("recurringActions", Features.recurringActions);

export const isMultiNumberChartWidgetsTeased = buildFeatureTeased("numberChartWidgets", Features.multiNumberChart);

export const isDelegationEnabled = buildFeatureEnabled("delegation", Features.delegation);

export const isTriggeredDiscussionsEnabled = buildFeatureEnabled("triggeredDiscussions", Features.triggeredDiscussions);

export const isCalculatedNumbersEnabled = buildFeatureEnabled("calculatedNumbers", Features.calculatedNumbers);

export const isAdvancedCalculationsEnabled = buildFeatureEnabled("calculatedNumbers",
    [Features.calculatedNumbers, Features.advancedCalculations]);

export const isMeetingNumberChartsEnabled = buildFeatureEnabled("minutesNumberCharts", Features.numberChartsForMinutes);

export const isApprovalsEnabled = buildFeatureEnabled("solutionApprovals", Features.approvals);

export const isLeagueEnabled = buildFeatureEnabled("league", Features.league);

export const isPerformanceTargetsEnabled = buildFeatureEnabled("performanceTargets", Features.performanceTargets);

export const isPersonalWatchlistsEnabled = buildFeatureEnabled("personalWatchlists", Features.personalWatchlists);

export const isTeamWatchlistsEnabled = buildFeatureEnabled("teamWatchlists", Features.teamWatchlists);

export const isWatchlistSharingEnabled = buildFeatureEnabled("watchlistSharing", Features.watchlistSharing);

export const isCustomMeetingStructureEnabled = buildFeatureEnabled("customMeetingStructure", Features.customMeetingStructure);

export const isXeroIntegrationEnabled = buildFeatureEnabled("externalIntegrations", Features.xeroIntegration);

export const isNoteEnforcementEnabled = buildFeatureEnabled("noteEnforcement", [Features.noteEnforcement, Features.negativeStatusNotes]);

export const isDailyUpdatedNumbersEnabled = buildFeatureEnabled("dailyUpdatedNumbers", Features.dailyUpdatedNumbers);

export const isTeamAnalyticsEnabled = buildFeatureEnabled("hrModule", Features.teamAnalytics);
export const isUserAnalyticsEnabled = buildFeatureEnabled("hrModule", Features.userAnalytics);

// #endregion
