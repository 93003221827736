import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NumberTargetDto } from "@api";

import { NumberType } from "~shared/enums";
import { hasLowerBound, hasUpperBound } from "~shared/util/number-helper";

@Component({
    selector: "app-number-target",
    templateUrl: "./number-target.component.html",
    styleUrls: ["./number-target.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberTargetComponent {

    @Input() target?: NumberTargetDto;
    @Input() numberType?: NumberType;

    get hasUpperBound(): boolean {
        return hasUpperBound(this.target);
    }

    get hasLowerBound(): boolean {
        return hasLowerBound(this.target);
    }

}
