<div class="ticker_sec top_row">
    <app-quarter-selector [companyId]="companyId$ | async" (selectedQuarterChange)="quarter = $event">
    </app-quarter-selector>
    <span class="qtr_status" [appDisable]="false">
        {{'reports.status' | translate}}:
        <mat-slide-toggle [formControl]="isCommittedControl">
            {{ (isCommittedControl.value ? "Committed" : "Draft") | translate }}
        </mat-slide-toggle>
    </span>
    <app-execution-score-target [selectedQuarter]="quarter$ | async"></app-execution-score-target>
</div>

<div class="header-row">
    <h2 class="main_heading" translate="reports.reports"></h2>

    <wf-help-indicator [matTooltip]="'reports.helpTooltip' | translate"></wf-help-indicator>

    <button type="button" mat-flat-button (click)="addReport()" class="add-button" color="primary"
        [appDisable]="false">
        <mat-icon>add</mat-icon>
        {{ 'reports.setupReport' | translate }}
    </button>

    <wf-feature-cap-display *ngIf="(hasReportCap$ | async) && (capStatus$ | async); let capStatus"
        [status]="capStatus"></wf-feature-cap-display>

    <div class="button-bar">
        <button type="button" mat-icon-button (click)="refresh()" color="accent" [title]="'refresh' | translate" [disabled]="isLoading">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>

<app-error-card *ngIf="hasFailed" (retry)="refresh()"
    [message]="'reports.loadFailed' | translate">
</app-error-card>

<div class="wf-page-body" *ngIf="!hasFailed && !!reports">
    <app-reports-table [reports]="reports" (reportEvent)="handleReportEvent($event)">
    </app-reports-table>
</div>