import { CurrentCompanyAndTeamDto } from "@api";

import { ColumnDefinition, defaultColumnBuilder } from "~services/table-settings.service";

import { coerceColumnSpec, ColumnSpec, ColumnSpecInput, ColumnVisibility } from "./column-helper";
import { isDelegationEnabled } from "./feature-helper";

export type NumberColumn =
    "description" | "week" | "owner" | "updater" | "team" |
    "result" | "target" | "resultToDate" | "targetToDate" |
    "resultSummary" | "department" | "category" | "subCategory";

const getColumnNameKey = (key: NumberColumn): string => {
    switch (key) {
        case "description": return "numbers.description";
        case "week": return "period.week";
        case "owner": return "numbers.owner";
        case "updater": return "numbers.updater";
        case "team": return "numbers.team";
        case "result": return "numbers.result";
        case "target": return "numbers.target";
        case "resultToDate": return "numbers.resultToDate";
        case "targetToDate": return "numbers.targetToDate";
        case "resultSummary": return "numbers.resultSummary";
        case "department": return "numbers.department";
        case "category": return "numbers.category";
        case "subCategory": return "numbers.subCategory";
    }
};

const getDefaultVisibility = (key: NumberColumn): ColumnVisibility => {
    switch (key) {
        case "description":
        case "result":
        case "target":
            return "fixed";
        case "week":
        case "resultSummary":
        case "owner":
        case "updater":
            return "visible";
        case "team":
        case "resultToDate":
        case "targetToDate":
        case "department":
        case "category":
        case "subCategory":
            return "hidden";
    }
};

const getColumnEnabled = (key: NumberColumn): ((ct: CurrentCompanyAndTeamDto | null) => boolean) | undefined => {
    switch (key) {
        case "team": return (ct) => isDelegationEnabled(ct);
    }
    return undefined;
};

const buildColumnDefinition = <TSpec extends ColumnSpec<NumberColumn>>(spec: TSpec):
    (ct: CurrentCompanyAndTeamDto | null) => ColumnDefinition<TSpec["key"]> => {
    const nameKey = getColumnNameKey(spec.key);
    const visibility = spec.visibility ?? getDefaultVisibility(spec.key);
    if (visibility === "fixed") {
        return () => defaultColumnBuilder(spec.key, nameKey, "visibility");
    }
    const columnEnabled = getColumnEnabled(spec.key);

    return (ct) => ({
        key: spec.key,
        nameKey,
        defaultVisibility: visibility === "visible",
        isEnabled: columnEnabled ? (() => columnEnabled(ct)) : undefined,
    });
};

export const createNumberColumnDefinitionBuilder = (columns: Readonly<ColumnSpecInput<NumberColumn>[]>):
    ((ct: CurrentCompanyAndTeamDto | null) => ColumnDefinition<NumberColumn>[]) => {
    const columnBuilders = columns.map(coerceColumnSpec).map(buildColumnDefinition);
    return ct => columnBuilders.map(b => b(ct));
};

export const DEFAULT_NUMBER_COLUMNS = [
    "description",
    "week",
    "result",
    "target",
    "resultToDate",
    "targetToDate",
    "resultSummary",
    "owner",
    "updater",
    "team",
    "department",
    "category",
    "subCategory",
] as const;

export const buildNumberColumnDefinitions = createNumberColumnDefinitionBuilder(DEFAULT_NUMBER_COLUMNS);
