import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    DiscussionAndSolutionDto, GetActionDto, GetReportDto, GlobalItemScheduleDto, PlanReportsApi, ReportRecordDetailDto,
    UpdateScheduleDto
} from "@api";
import { TranslateService } from "@ngx-translate/core";
import {
    BehaviorSubject, catchError, distinctUntilChanged, EMPTY, finalize, Observable, of, Subject, Subscription, switchMap, tap
} from "rxjs";

import { DeleteReportDialogComponent, EditReportDialogComponent } from "~/app/quarterly-planning/pages";
import { FeedAdapterBuilder, FeedScope, SimpleFeedScope } from "~feed";
import { AccessService, AccessState } from "~services/access.service";
import { NotificationService } from "~services/notification.service";
import {
    ActionStateService, DiscussionStateService, mergeRecordUpdatesFrom, ReportStateEvent, ReportStateService
} from "~services/state";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { EntityType, PageName, PlanningStatus } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeInAnimationBuilder } from "~shared/util/animations";
import { mergeChildUpdatesFrom } from "~shared/util/children-state-helper";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { getPlanningStatusNameKey, getUpdateScheduleDescription } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

import { HomepageScaffoldComponent } from "../homepage-scaffold/homepage-scaffold.component";

export type ReportDto = GetReportDto | ReportRecordDetailDto;

const isRecord = (report: ReportDto): report is ReportRecordDetailDto =>
    "week" in report;

@Component({
    selector: "app-report-homepage",
    templateUrl: "./report-homepage.component.html",
    styleUrls: ["./report-homepage.component.scss"],
    providers: [
        SimpleFeedScope,
        {
            provide: FeedScope,
            useExisting: SimpleFeedScope,
        },
    ],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
    ],
})
export class ReportHomepageComponent<TReport extends ReportDto> extends WithDestroy() implements OnInit, OnDestroy {

    @Input() set report(value: TReport | null) {
        this.reportSubject.next(value);
        this.simpleFeedScope.adapter = value ? this.feedAdapterBuilder.buildForReport(value) : null;
    }

    get report(): TReport | null {
        return this.reportSubject.value;
    }

    @Input() set allowEdit(value: BooleanInput) {
        this.allowEditInternal = coerceBooleanProperty(value);
    }

    get allowEdit(): boolean {
        return this.allowEditInternal;
    }

    @Output() reportChange = new EventEmitter<TReport>();
    @Output() reportDeleted = new EventEmitter<TReport>();

    @Output() scheduleChange = new EventEmitter<GlobalItemScheduleDto>();

    @ViewChild(HomepageScaffoldComponent) scaffold?: HomepageScaffoldComponent;

    readonly schedule$: Observable<GlobalItemScheduleDto[]>;

    readonly records$: Observable<ReportRecordDetailDto[]>;
    isLoadingRecords = true;
    recordsHasError = false;

    readonly actions$: Observable<GetActionDto[]>;
    isLoadingActions = true;
    actionsHasError = false;

    readonly discussions$: Observable<DiscussionAndSolutionDto[]>;
    isLoadingDiscussions = true;
    discussionsHasError = false;

    readonly access$: Observable<AccessState>;

    readonly getUserName = getUserName;
    readonly getPlanningStatusNameKey = getPlanningStatusNameKey;

    readonly isRecord = isRecord;

    private allowEditInternal = false;

    private readonly reportSubject = new BehaviorSubject<TReport | null>(null);
    private readonly selectScheduleSubject = new Subject<GlobalItemScheduleDto>();
    private readonly refreshRecordsSubject = new BehaviorSubject<void>(undefined);
    private readonly refreshActionsSubject = new BehaviorSubject<void>(undefined);
    private readonly refreshDiscussionsSubject = new BehaviorSubject<void>(undefined);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planReportsApi: PlanReportsApi,
        private readonly reportStateService: ReportStateService,
        private readonly actionStateService: ActionStateService,
        private readonly discussionStateService: DiscussionStateService,
        private readonly simpleFeedScope: SimpleFeedScope,
        private readonly feedAdapterBuilder: FeedAdapterBuilder,
        private readonly accessService: AccessService,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly translate: TranslateService,
    ) {
        super();

        this.access$ = this.reportSubject.pipe(
            switchMap(report => {
                if (!report) return of(AccessState.disabled);
                if (report.isDelegated) return of({ canRead: true, canEdit: false, canDelete: false });
                return this.accessService.getAccessState(report.company.id, report.team.id, PageName.quarterlyPlanning);
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.schedule$ = this.reportSubject.pipe(
            switchMap(report => {
                if (!report) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(report);
                return this.planReportsApi.getReportSchedule(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: report.financialYear, quarter: report.planningPeriod }),
                    report.id,
                ).pipe(
                    catchError(() => of([])),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        const distinctReport$ = this.reportSubject.pipe(
            distinctUntilChanged((a, b) => a?.id === b?.id),
        );

        this.actions$ = distinctReport$.pipe(
            withRefresh(this.refreshActionsSubject),
            tap(() => {
                this.isLoadingActions = true;
                this.actionsHasError = false;
            }),
            switchMap(report => {
                if (!report) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(report);
                return (!report.actionsCount ? of([]) : this.planReportsApi.getActionsForReport(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: report.financialYear, quarter: report.planningPeriod }),
                    report.id,
                )).pipe(
                    mergeChildUpdatesFrom(this.actionStateService.events$, report.id, EntityType.report),
                    catchError(() => {
                        this.actionsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingActions = false),
            shareReplayUntil(this.destroyed$),
        );

        this.discussions$ = distinctReport$.pipe(
            withRefresh(this.refreshDiscussionsSubject),
            tap(() => {
                this.isLoadingDiscussions = true;
                this.discussionsHasError = false;
            }),
            switchMap(report => {
                if (!report) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(report);
                return (!report.discussionsCount ? of([]) : this.planReportsApi.getDiscussionsForReport(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: report.financialYear, quarter: report.planningPeriod }),
                    report.id,
                )).pipe(
                    mergeChildUpdatesFrom(this.discussionStateService.events$, report.id, EntityType.report),
                    catchError(() => {
                        this.discussionsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingDiscussions = false),
            shareReplayUntil(this.destroyed$),
        );

        this.records$ = distinctReport$.pipe(
            withRefresh(this.refreshRecordsSubject),
            tap(() => {
                this.isLoadingRecords = true;
                this.recordsHasError = false;
            }),
            switchMap(report => {
                if (!report) return of([]);
                const { company, team } = getDelegatedItemCompanyTeam(report);
                return this.planReportsApi.getReportRecords(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: report.financialYear, quarter: report.planningPeriod }),
                    report.id,
                ).pipe(
                    mergeRecordUpdatesFrom(data => this.reportStateService.eventsForReports(...data)),
                    catchError(() => {
                        this.recordsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingRecords = false),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.reportSubject.pipe(
            switchMap(report => !report ? EMPTY : this.reportStateService.eventsForReports(report)),
        ).subscribe(this.handleStateEvent));

        this.subscriptions.add(this.selectScheduleSubject.pipe(
            switchMap(schedule => {
                const report = this.report;
                if (!report) return EMPTY;
                CommonFunctions.setLoader(true);
                const { company, team } = getDelegatedItemCompanyTeam(report);
                return this.planReportsApi.getReport(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: schedule.financialYear, quarter: schedule.planningPeriod }),
                    schedule.id,
                ).pipe(
                    catchError(() => {
                        this.notificationService.errorUnexpected();
                        return EMPTY;
                    }),
                    finalize(() => CommonFunctions.setLoader(false)),
                );
            }),
        ).subscribe(report => this.report = report as TReport));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    selectSchedule = (schedule: GlobalItemScheduleDto) => {
        this.scheduleChange.emit(schedule);
        this.selectScheduleSubject.next(schedule);
    };

    view = () => {
        const report = this.report;
        if (!report) return;

        EditReportDialogComponent.openForEdit(this.dialog, report, undefined, /* readonly: */ true)
            .afterClosed().subscribe(res => res ? this.afterUpdated(res as TReport) : null);
    };

    edit = () => {
        const report = this.report;
        if (!report || isRecord(report)) return;
        if (report.planningStatus === PlanningStatus.locked) {
            // Show the warning, but continue to show the read-only dialog
            this.notificationService.warning("reports.editLockWarning", undefined, undefined, true);
        }
        EditReportDialogComponent.openForEdit(this.dialog, report)
            .afterClosed().subscribe(res => res ? this.afterUpdated(res as TReport) : null);
    };

    delete = () => {
        const report = this.report;
        if (!report || isRecord(report)) return;
        if (report.planningStatus === PlanningStatus.locked) {
            this.notificationService.warning("reports.deleteLockWarning", undefined, undefined, true);
            return;
        }
        DeleteReportDialogComponent.open(this.dialog, report)
            .afterClosed().subscribe(res => res ? this.afterDeleted() : null);
    };

    refreshActions = () => this.refreshActionsSubject.next();

    getUpdateScheduleDescription = (schedule: UpdateScheduleDto): string | null =>
        getUpdateScheduleDescription(this.translate, schedule);

    recordUpdated = (record: ReportRecordDetailDto) => {
        const report = this.report;
        if (!report) return;

        if (isRecord(report) && record.week === report.week) {
            // We've updated the status for the selected week.
            // No further calculation needs to be done - emit as is.
            this.afterUpdated(record as TReport);
        } else {
            // The updated week is not the selected week. Reload all records.

            // Note: unlike with goals and numbers, we do not need to notify an update here, as updating one record
            // will never update either the definition or another record - this is not true for goals or numbers.
            // (Updating a goal to complete will mark future weeks as complete. Updating a number result may change result to date.)
            this.scaffold?.refreshFeed();
            this.refreshRecordsSubject.next();
        }
    };

    afterUpdated = (report: TReport) => {
        this.afterUpdatedInternal(report);
        this.refreshRecordsSubject.next();
    };

    afterDeleted = () => this.reportDeleted.emit(this.report ?? undefined);

    private afterUpdatedInternal = (report: TReport) => {
        if (report === this.report) return;
        this.report = report;
        this.reportChange.emit(report);
        this.scaffold?.refreshFeed();
    };

    private handleStateEvent = (event: ReportStateEvent) => {
        switch (event.type) {
            case "added": // We should never get an added event, but treat it as if updated for simplicity
            case "updated":
                const report = this.report;
                if (!report) return;

                if (isRecord(report) && event.item.week === report.week) {
                    // The result for the selected week has been updated.
                    this.afterUpdatedInternal(event.item as TReport);
                }
                break;
            case "deleted":
                this.afterDeleted();
                break;
        }
    };
}
