import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NumberTargetDto } from "@api";

import { NumberType } from "~shared/enums";
import { getNumberTargetStatus, NumberStatus } from "~shared/util/number-helper";

@Component({
    selector: "app-number-result",
    templateUrl: "./number-result.component.html",
    styleUrls: ["./number-result.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberResultComponent {

    @Input() result?: number;
    @Input() target?: NumberTargetDto;
    @Input() numberType?: NumberType;

    get targetStatus(): NumberStatus {
        return getNumberTargetStatus(this.result, this.target);
    }

    get hasResult(): boolean {
        return this.result !== null && this.result !== undefined;
    }

}
