<h4 mat-dialog-title>
    <wf-translate-template key="performance.numbersFor"
        [params]="{ teamName: team.name, financialYear, quarter, week }">
        <span *wfTransTplContent="'teamName'; let content" class="team-name">{{ content }}</span>
    </wf-translate-template>
</h4>

<mat-dialog-content>
    <table mat-table class="wf-table" matSort [dataSource]="dataSource" wfTableFocus>
    
        <ng-container matColumnDef="trend">
            <th mat-header-cell *matHeaderCellDef>
                {{'numbers.trend' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button matTooltip="{{'numbers.trendGraph' | translate}}"
                    (click)="showNumberGraph(element)">
                    <mat-icon>trending_up</mat-icon>
                </button>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                {{'numbers.description' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" class="wf-column-heading">
                <a (click)="viewNumber(element)" wfEmptyLink>
                    <app-number-description-column [number]="element"></app-number-description-column>
                </a>
                <app-notes-callout *ngIf="element.notes" [notes]="element.notes"></app-notes-callout>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef>
                <app-table-user-filter [team]="team" (selected)="applyOwnerFilter($event)" withSortHeader>
                    {{'numbers.owner' | translate}}
                </app-table-user-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                <app-delegated-user-display [entity]="element">
                    {{ getUserName(element.owner) }}
                    <wf-private-indicator *ngIf="element.isPrivate"></wf-private-indicator>
                </app-delegated-user-display>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="updater">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'numbers.updater' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <app-delegated-user-display [entity]="element" mode="updater" *ngIf="allowsUpdater(element)">
                    {{ getUserName(element.updater) }}
                </app-delegated-user-display>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
                {{'numbers.result' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="readonly-result-layout">
                    <app-number-calc-sources-button [number]="element" *ngIf="isCalculated(element) && calculationEnabled || element.dailyUpdateDefinition && dailyUpdatesEnabled">
                    </app-number-calc-sources-button>
                    <app-number-result [result]="element.result" [target]="element.recordTarget"
                        [numberType]="element.type"></app-number-result>
                </div>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
                {{'numbers.target' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <app-number-target [target]="element.recordTarget" [numberType]="element.type"></app-number-target>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <app-feed-button (openFeed)="openFeed(element)" [reference]="element.feedPartition"></app-feed-button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let element; let i = index; columns: columns;" [wfRowFocus]="element"
            [wfRowFocusIndex]="i">
        </tr>
        <tr class="mat-mdc-row" *matNoDataRow>
            <td [attr.colspan]="columns.length">
                <span class="no-records">
                    {{'NoRecordsFound.noRecordsFound!' | translate}}
                </span>
            </td>
        </tr>
    </table>
</mat-dialog-content>
