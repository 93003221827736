// eslint-disable-next-line max-classes-per-file
import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, booleanAttribute, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild, ViewEncapsulation } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { PlanningPeriodDetailsDto } from "@api";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, of, Subject, Subscription, switchMap, tap } from "rxjs";

import { IQuarter, PeriodRepository } from "~repositories";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortNumber } from "~shared/util/sorters";
import { valueAndChanges } from "~shared/util/util";

class QuarterFieldBase {
    readonly stateChanges = new Subject<void>();
    constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl) { }
}

const quarterFieldMixinBase = WithDestroy(mixinErrorState(QuarterFieldBase));

const toQuarter = (period: PlanningPeriodDetailsDto): IQuarter => ({
    financialYear: period.financialYear,
    quarter: period.index,
});

/**
 * Compares the two quarters.
 *
 * @param o1 The first quarter
 * @param o2 The second quarter
 * @returns Positive if the second is later, negative if the second is earlier, 0 if they are the same.
 */
const compareQuarters = (o1: IQuarter, o2: IQuarter): number =>
    (o2.financialYear - o1.financialYear) || (o2.quarter - o1.quarter);

const areQuartersEqual = (o1: IQuarter | null, o2: IQuarter | null): boolean => {
    if (!o1 && !o2) return true;
    if (!o1 || !o2) return false;
    return compareQuarters(o1, o2) === 0;
};;

const sanitisePeriod = (period: IQuarter | null, periods: PlanningPeriodDetailsDto[]): IQuarter | null => {
    // If we don't have any periods, do no sanitisation. Any fixes will be applied when the periods load.
    if (!periods.length) return period;

    if (period == null) {
        // We should pick a default from the periods list, attempting to use the current one.
        const currentDate = moment().startOf("day").utc(true);
        const currentQuarter = periods.find(q => currentDate.isSameOrAfter(q.startDate) && currentDate.isBefore(q.endDate));
        if (currentQuarter) return toQuarter(currentQuarter);
        // If the current date is prior to any quarter, use the first quarter.
        if (periods.every(q => currentDate.isBefore(q.startDate))) {
            return {
                financialYear: periods[0].financialYear,
                quarter: 1,
            };
        }
        // Otherwise, use the last quarter.
        return toQuarter(periods[periods.length - 1]);
    }

    // If a valid period, leave it as is.
    if (periods.some(q => areQuartersEqual(toQuarter(q), period))) return period;
    // Otherwise, if the period is prior to any existing period, use the first
    if (period.quarter < 1) {
        return {
            financialYear: periods[0].financialYear,
            quarter: 1,
        };
    }
    // Otherwise, the period must be later than any other period, so use the last.
    return toQuarter(periods[periods.length - 1]);
};

@Component({
    selector: "wf-quarter-field",
    templateUrl: "./quarter-field.component.html",
    styleUrls: ["./quarter-field.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MatFormFieldControl, useExisting: QuarterFieldComponent }],
    host: {
        class: "wf-quarter-field",
    },
})
export class QuarterFieldComponent
    extends quarterFieldMixinBase
    implements OnInit, AfterViewInit, OnDestroy, MatFormFieldControl<IQuarter | null>, ControlValueAccessor {

    private static nextId = 0;

    get companyId(): string | null {
        return this.companyIdSubject.value;
    }

    @Input() set companyId(value: string | null | undefined) {
        this.companyIdSubject.next(value ?? null);
    }

    get financialYear(): number | null {
        return this.financialYearSubject.value;
    }

    @Input() set financialYear(value: number | null) {
        this.financialYearSubject.next(value);
        if (this.disabled) return;
        if (value == null) {
            this.financialYearControl.enable();
        } else {
            this.financialYearControl.disable();
        }
    }

    get min(): IQuarter | null {
        return this.minValueSubject.value;
    }

    @Input() set min(value: IQuarter | null) {
        this.minValueSubject.next(value);
    }

    get max(): IQuarter | null {
        return this.maxValueSubject.value;
    }

    @Input() set max(value: IQuarter | null) {
        this.maxValueSubject.next(value);
    }

    //#region MatFormFieldControl implementation - properties
    /* eslint-disable @typescript-eslint/member-ordering */

    @HostBinding() id = `quarter-field-input-${QuarterFieldComponent.nextId++}`;

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input() get placeholder(): string {
        return this.placeholderInternal ?? "";
    }

    set placeholder(value: string) {
        this.placeholderInternal = value;
    }

    get required(): boolean {
        return this.requiredInternal ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
    }

    @Input({ transform: coerceBooleanProperty }) set required(value: boolean) {
        this.requiredInternal = value;
    }

    get disabled(): boolean {
        return this.disabledSubject.value;
    }

    @Input({ transform: booleanAttribute }) set disabled(value: boolean) {
        this.disabledSubject.next(value);
        if (value) {
            this.form.disable();
        } else {
            if (this.financialYear == null) {
                this.financialYearControl.enable();
            } else {
                this.financialYearControl.disable();
            }
            this.quarterControl.enable();
        }
        this.stateChanges.next();
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("aria-describedby") userAriaDescribedBy?: string;

    get value(): IQuarter | null {
        return this.selectedQuarterSubject.value;
    }

    @Input() set value(value: IQuarter | null) {
        value = this.sanitisePeriod(value);
        this.setFormValue(value);
        this.selectedQuarterSubject.next(value);
        this.onChangedCallback?.(value);
        this.stateChanges.next();
    }

    @Output() valueChange = new EventEmitter<IQuarter | null>();

    get empty() {
        return this.value == null;
    }

    focused = false;
    controlType = "quarter-field-input";
    ariaDescribedBy?: string;
    //#endregion
    /* eslint-enable @typescript-eslint/member-ordering */

    @ViewChild("yearSelect") yearSelect: MatSelect | undefined;
    @ViewChild("quarterSelect") quarterSelect: MatSelect | undefined;

    @HostBinding("class.wf-quarter-field-year-panel-open") get yearPanelOpen() {
        return this.yearSelect?.panelOpen ?? false;
    }

    @HostBinding("class.wf-quarter-field-quarter-panel-open") get quarterPanelOpen() {
        return this.quarterSelect?.panelOpen ?? false;
    }

    readonly financialYearControl = new FormControl<number | null>(null);
    readonly quarterControl = new FormControl<number | null>(null);

    readonly form = new FormGroup({
        financialYear: this.financialYearControl,
        quarter: this.quarterControl,
    });

    readonly areQuartersEqual = areQuartersEqual;

    readonly financialYears$: Observable<number[]>;
    readonly quarters$: Observable<number[]>;

    private readonly companyIdSubject = new BehaviorSubject<string | null>(null);
    private readonly financialYearSubject = new BehaviorSubject<number | null>(null);
    private readonly selectedQuarterSubject = new BehaviorSubject<IQuarter | null>(null);
    private readonly minValueSubject = new BehaviorSubject<IQuarter | null>(null);
    private readonly maxValueSubject = new BehaviorSubject<IQuarter | null>(null);
    private readonly periodsSubject = new BehaviorSubject<PlanningPeriodDetailsDto[]>([]);
    private readonly disabledSubject = new BehaviorSubject<boolean>(false);

    private readonly subscriptions = new Subscription();

    private placeholderInternal: string | undefined | null;
    private requiredInternal: boolean | undefined;

    private onChangedCallback?: (_: IQuarter | null) => void;
    private onTouchedCallback?: () => void;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private readonly periodRepository: PeriodRepository,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        const selectedFinancialYear$ = this.financialYearSubject.pipe(
            switchMap(year => {
                if (year != null) return of(year);
                return valueAndChanges(this.financialYearControl);
            }),
            distinctUntilChanged(),
        );

        this.financialYears$ = selectedFinancialYear$.pipe(
            map(year => !year ? [] : new Array(5).fill(0).map((_, i) => year + i - 2)),
            shareReplayUntil(this.destroyed$),
        );

        this.quarters$ = this.periodsSubject.pipe(
            map(periods => periods.map(p => p.index)),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.form.valueChanges.subscribe(({ financialYear, quarter }) => {
            if (this.disabled) return;
            financialYear ??= this.financialYear;
            const value = financialYear != null && quarter != null ? { financialYear, quarter } : null;
            if (areQuartersEqual(value, this.selectedQuarterSubject.value)) return;

            this.selectedQuarterSubject.next(value);
            this.valueChange.emit(value);
            this.onChangedCallback?.(value);
            this.onTouchedCallback?.();
            this.stateChanges.next();
        }));

        this.subscriptions.add(combineLatest({
            companyId: this.companyIdSubject,
            financialYear: this.financialYearSubject.pipe(
                switchMap(year => {
                    if (year != null) return of(year);
                    return this.selectedQuarterSubject.pipe(
                        map(q => q?.financialYear ?? null),
                    );
                }),
                distinctUntilChanged(),
            ),
            disabled: this.disabledSubject,
        }).pipe(
            switchMap(({ companyId, financialYear, disabled }) => {
                if (!companyId || financialYear == null || disabled) return of([]);
                return this.periodRepository.getPeriods(companyId, financialYear).pipe(
                    map(periods => periods.sort(sortNumber.ascending(p => p.index))),
                    switchMap(periods => combineLatest({
                        min: this.minValueSubject,
                        max: this.maxValueSubject
                    }).pipe(
                        map(({ min, max }) => {
                            let filteredPeriods = [...periods];
                            if (min) {
                                filteredPeriods = filteredPeriods.filter(p => compareQuarters(min, toQuarter(p)) >= 0);
                            }
                            if (max) {
                                filteredPeriods = filteredPeriods.filter(p => compareQuarters(toQuarter(p), max) >= 0);
                            }
                            return filteredPeriods;
                        }),
                    )),
                    tap(this.fixSelectedPeriod),
                );
            })
        ).subscribe(this.periodsSubject));
    }

    ngAfterViewInit(): void {
        if (this.yearSelect) {
            this.subscriptions.add(this.fm.monitor(this.yearSelect._elementRef, true).subscribe(origin => {
                if (this.disabled || this.financialYearControl.disabled) return;
                if (!!origin && origin !== "program") {
                    this.yearSelect?.open();
                }
                this.focused = !this.disabled && !!origin;
                this.stateChanges.next();
            }));
        }
        if (this.quarterSelect) {
            this.subscriptions.add(this.fm.monitor(this.quarterSelect._elementRef, true).subscribe(origin => {
                if (this.disabled) return;
                if (!!origin && origin !== "program") {
                    this.quarterSelect?.open();
                }
                this.focused = !this.disabled && !!origin;
                this.stateChanges.next();
            }));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    //#region MatFormFieldControl implementation - methods
    setDescribedByIds(ids: string[]) {
        this.ariaDescribedBy = ids.join(" ");
    }

    onContainerClick(_: MouseEvent) {
        if (this.disabled) return;
        if (this.yearSelect?.panelOpen) return;
        this.quarterSelect?.focus();
        this.quarterSelect?.open();
    }
    //#endregion

    //#region ControlValueAccessor implementation
    /* eslint-disable @typescript-eslint/no-explicit-any */
    writeValue(obj: any): void {
        const value = this.sanitisePeriod(obj);
        this.setFormValue(value);
        this.selectedQuarterSubject.next(value);
        this.valueChange.emit(value);
        this.stateChanges.next();
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
    //#endregion

    private fixSelectedPeriod = (periods: PlanningPeriodDetailsDto[]) => {
        if (this.disabled) return; // If disabled, we don't want to make any changes
        const sanitisedPeriod = sanitisePeriod(this.value, periods);
        if (areQuartersEqual(sanitisedPeriod, this.value)) return;
        this.setFormValue(sanitisedPeriod);
        this.selectedQuarterSubject.next(sanitisedPeriod);
        this.valueChange.emit(sanitisedPeriod);
        this.onChangedCallback?.(sanitisedPeriod);
    };

    private sanitisePeriod = (period: IQuarter | null): IQuarter | null => {
        if (this.disabled) return period; // If disabled, we don't want to make any changes
        return sanitisePeriod(period, this.periodsSubject.value);
    };

    private setFormValue = (value: IQuarter | null) =>
        this.form.setValue({
            financialYear: value?.financialYear ?? null,
            quarter: value?.quarter ?? null,
        }, { emitEvent: false });

}
