import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
    ActionSuggestionDto, DiscussionAndSolutionDto, DiscussionSuggestionsApi, SolutionActionsRequestDto
} from "@api";
import { BehaviorSubject, catchError, delay, EMPTY, filter, Observable, of, Subject, Subscription, switchMap, tap } from "rxjs";

import { exampleActions } from "~/app/quarterly-planning/pages/goals/suggest-goal-actions-dialog/example-actions";
import { environment } from "~/environments/environment";
import { NotificationService } from "~services/notification.service";
import { EditActionDialogComponent } from "~shared/dialogs";

import { IAddActionForSolutionResult } from "../edit-action-dialog/edit-action-dialog.component";

export interface SuggestSolutionActionsDialogData {
    discussion: DiscussionAndSolutionDto;
    solution: SolutionActionsRequestDto;
}

@Component({
    selector: "app-suggest-solution-actions-dialog",
    templateUrl: "./suggest-solution-actions-dialog.component.html",
    styleUrls: ["./suggest-solution-actions-dialog.component.scss"]
})
export class SuggestSolutionActionsDialogComponent implements OnInit, OnDestroy {

    readonly dataSource = new MatTableDataSource<ActionSuggestionDto>();
    readonly displayedColumns = ["description", "apply"];
    isLoading = true;

    get discussionHeading(): string {
        return this.discussion.heading;
    }

    readonly actions$: Observable<IAddActionForSolutionResult>;

    private readonly discussion: DiscussionAndSolutionDto;
    private readonly solution: SolutionActionsRequestDto;

    private readonly appliedSuggestions = new Map<ActionSuggestionDto, IAddActionForSolutionResult>();

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);
    private readonly actionsInternal = new Subject<IAddActionForSolutionResult>();
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly discussionSuggestionsApi: DiscussionSuggestionsApi,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<SuggestSolutionActionsDialogComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: SuggestSolutionActionsDialogData,
    ) {
        this.actions$ = this.actionsInternal.asObservable();
        this.discussion = data.discussion;
        this.solution = data.solution;
    }

    static open(dialog: MatDialog, discussion: DiscussionAndSolutionDto, solution: SolutionActionsRequestDto) {
        return dialog.open(SuggestSolutionActionsDialogComponent, {
            width: "800px",
            data: { discussion, solution },
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.refreshSubject.pipe(
            tap(() => this.isLoading = true),
            switchMap(() => this.getSuggestions()),
            catchError(() => {
                this.dialogRef.close();
                this.notificationService.errorUnexpected();
                return EMPTY;
            }),
            switchMap(suggestions => {
                if (!suggestions.length) {
                    this.dialogRef.close();
                    this.notificationService.warning("discussions.solution.suggest.noActionSuggestions", undefined, undefined, true);
                    return EMPTY;
                }
                return of(suggestions);
            }),
            tap(() => this.isLoading = false),
        ).subscribe(suggestions => this.dataSource.data = suggestions));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.actionsInternal.complete();
    }

    applySuggestion = (suggestion: ActionSuggestionDto) => {
        if (this.isApplied(suggestion)) return;
        const discussion = this.discussion;
        EditActionDialogComponent.openForAdd(this.dialog, {
            companyId: discussion.company.id,
            teamId: discussion.team.id,
            fromSolution: true,
            actionInput: {
                ...suggestion,
                departmentId: discussion.department?.id,
                categoryId: discussion.category?.id,
                subCategoryId: discussion.category?.subCategory?.id,
            }
        }).afterClosed().pipe(filter(Boolean)).subscribe(result => {
            if (!result || result.type !== "addActionForSolution") return;
            this.appliedSuggestions.set(suggestion, result);
            this.actionsInternal.next(result);
        });
    };

    isApplied = (suggestion: ActionSuggestionDto) => this.appliedSuggestions.has(suggestion);

    private getSuggestions = (): Observable<ActionSuggestionDto[]> => {
        if (environment.localSuggestions) {
            return of(exampleActions).pipe(
                delay(1000),
            );
        }
        const discussion = this.discussion;
        return this.discussionSuggestionsApi.getSolutionActionSuggestions(
            discussion.company.id,
            discussion.team.id,
            discussion.id,
            this.solution);
    };

}
