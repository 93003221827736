import { Environment } from "./environment.core";

export const environment: Environment = {
    production: true,
    auth: {
        authority: "https://auth.beta.workfacta.com",
        clientId: "workfacta-web",
    },
    timeMachine: false,
    demoServer: false,
    intercomAppId: "jv66fizq",
    helpCentreUrl: "https://help-test.workfacta.com",
    gtm: {
        id: "GTM-5TGM9PS",
        auth: "t0Lt31RFWXGqpjD_90L_NA",
        environment: "env-3"
    },
    tableRowFocus: true,
};
