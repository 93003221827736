import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CaptureMethod, GetNumberDto, NumberRecordDetailDto, UpdateNumbersApi } from "@api";
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap } from "rxjs";

import { TeamContext } from "~services/contexts";
import { mergeRecordUpdatesFrom, NumberStateService } from "~services/state";
import { getDisplayedColumns$, TableSettings, TableSettingsService } from "~services/table-settings.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { createNumberColumnDefinitionBuilder, NumberColumn } from "~shared/util/number-columns";
import { getNumberChildren, sortNumberDefinition } from "~shared/util/number-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortCompanyTeam, sortMultiple } from "~shared/util/sorters";

interface ViewNumberCalcSourcesDialogData {
    number: GetNumberDto | NumberRecordDetailDto;
    week: number;
}

const TABLE_SETTINGS_NAME = "NumberCalcSources";

const buildColumnDefinitions = createNumberColumnDefinitionBuilder([
    "description",
    ["week", "hidden"],
    ["team", "visible"],
    "owner",
    "updater",
    "resultToDate",
    "targetToDate",
    "result",
    "target",
    "resultSummary",
    "department",
    "category",
    "subCategory",
]);

@Component({
    selector: "app-view-number-calc-sources-dialog",
    templateUrl: "./view-number-calc-sources-dialog.component.html",
    styleUrls: ["./view-number-calc-sources-dialog.component.scss"],
})
export class ViewNumberCalcSourcesDialogComponent extends WithDestroy() {

    readonly number: GetNumberDto | NumberRecordDetailDto;
    readonly week: number;

    readonly sources$: Observable<NumberRecordDetailDto[]>;

    readonly columnDefinitions$ = this.teamContext.companyTeam$.pipe(
        map(buildColumnDefinitions),
        shareReplayUntil(this.destroyed$),
    );

    readonly tableSettings$ = this.columnDefinitions$.pipe(
        switchMap(defs => this.tableSettingsService.getTableSettings(TABLE_SETTINGS_NAME, defs)),
        shareReplayUntil(this.destroyed$),
    );

    readonly displayedColumns$ = getDisplayedColumns$(this.tableSettings$, this.columnDefinitions$).pipe(
        shareReplayUntil(this.destroyed$),
    );

    isLoading = false;
    hasError = false;

    get isCalculated(): boolean {
        return this.number.captureMethod === CaptureMethod.calculated;
    }

    get isDailyUpdated(): boolean {
        return !!this.number.dailyUpdateDefinition;
    }

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private readonly updateNumbersApi: UpdateNumbersApi,
        private readonly numberStateService: NumberStateService,
        private readonly teamContext: TeamContext,
        private readonly tableSettingsService: TableSettingsService,
        @Inject(MAT_DIALOG_DATA) { number, week }: ViewNumberCalcSourcesDialogData,
    ) {
        super();

        this.number = number;
        this.week = week;

        this.sources$ = this.refreshSubject.pipe(
            tap(this.initLoad),
            switchMap(() => this.getCalculationSources(number, week).pipe(
                mergeRecordUpdatesFrom(data => this.numberStateService.eventsForNumbers(...data)),
                map(numbers => numbers.sort(sortMultiple(
                    sortNumberDefinition.ascending(),
                    sortCompanyTeam.ascending(),
                ))),
                catchError(() => {
                    this.hasError = true;
                    return of([]);
                }),
            )),
            tap(() => this.isLoading = false),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: MatDialog, number: GetNumberDto | NumberRecordDetailDto, week: number) {
        return dialog.open(ViewNumberCalcSourcesDialogComponent, {
            data: { number, week },
            autoFocus: "first-heading",
        });
    }

    refresh = () => this.refreshSubject.next();

    tableUpdated = (settings: TableSettings<NumberColumn>) =>
        this.tableSettingsService.setTableSettings(TABLE_SETTINGS_NAME, settings);

    private initLoad = () => {
        this.isLoading = true;
        this.hasError = false;
    };

    private getCalculationSources = (number: GetNumberDto | NumberRecordDetailDto, week: number): Observable<NumberRecordDetailDto[]> => {
        const { company, team } = getDelegatedItemCompanyTeam(number);

        if (number.dailyUpdateDefinition) {
            if (number.children && "week" in number && number.week === week) {
                // We have enough info in the DTO to just build the children without getting from the server.
                return of(getNumberChildren(number));
            }
            return this.updateNumbersApi.getDailyChildren(
                company.id,
                team.id,
                toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                week,
                number.id,
            );
        }

        return this.updateNumbersApi.getCalculationSources(
            company.id,
            team.id,
            toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
            week,
            number.id,
        );
    };
}
