import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
    BehaviorSubject, catchError, combineLatest, defer, EMPTY, filter,
    map, Observable, of, startWith, Subscription, switchMap, tap
} from "rxjs";

import {
    CurrentCompanyDto, CurrentTeamDto, FeatureCapStatusDto,
    GetReportDto, ListReportsDto, PlanReportsApi
} from "~/api";
import { ReportCappedDialogComponent } from "~/app/plan-shared/dialogs";
import { PlanningStatus } from "~/app/shared/enums";
import { IQuarter } from "~repositories";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortString } from "~shared/util/sorters";

import { ReportEvent } from "../../components";
import { DeleteReportDialogComponent } from "./delete-report-dialog/delete-report-dialog.component";
import { EditReportDialogComponent } from "./edit-report-dialog/edit-report-dialog.component";

@Component({
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
    host: {
        class: "wf-page",
    },
})

export class ReportsComponent extends WithDestroy() implements OnInit, OnDestroy {

    readonly companyId$: Observable<string>;
    readonly quarter$: Observable<IQuarter | null>;

    readonly isCommittedControl = new FormControl(false, { nonNullable: true });

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        if (value) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
        this.isLoadingInternal = value;
    }

    hasFailed = false;

    set quarter(value: IQuarter) {
        this.quarterSubject.next(value);
    }

    reports?: GetReportDto[];

    readonly hasReportCap$: Observable<boolean>;
    readonly capStatus$: Observable<FeatureCapStatusDto>;

    private isLoadingInternal = false;
    private isCheckingCap = false;

    private readonly quarterSubject = new BehaviorSubject<IQuarter | null>(null);
    private readonly dataSubject = new BehaviorSubject<ListReportsDto | null>(null);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planReportsApi: PlanReportsApi,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.companyId$ = this.teamContext.companyTeam$.pipe(
            filter(Boolean),
            map(ct => ct.company.id)
        );

        this.hasReportCap$ = this.teamContext.companyTeam$.pipe(
            map(ct => ct?.company.planTier.featureConstraints.reportCount),
            map(c => c !== null && c !== undefined),
        );
        this.capStatus$ = combineLatest({
            ct: this.teamContext.companyTeam$,
            quarter: this.quarterSubject,
        }).pipe(
            switchMap(({ ct, quarter }) => {
                if (!ct || !ct.team || !quarter) {
                    return of({
                        canAdd: true,
                        actualCount: 0,
                        cap: undefined
                    });
                }
                return this.getCapStatus(ct.company, ct.team, quarter);
            }),
            shareReplayUntil(this.destroyed$),
        );
        this.quarter$ = this.quarterSubject.pipe(shareReplayUntil(this.destroyed$));
    }

    ngOnInit() {
        this.subscriptions.add(this.dataSubject.subscribe(data => {
            if (data) {
                this.isCommittedControl.reset(data.planningStatus === PlanningStatus.locked, { emitEvent: false });
                this.reports = data?.reports.sort(sortString.ascending(r => r.description));
            }
            if (!data || !data.reports.length) {
                this.isCommittedControl.disable({ emitEvent: false });
            } else {
                this.isCommittedControl.enable({ emitEvent: false });
            }
        }));

        this.subscriptions.add(combineLatest({
            quarter: this.quarter$.pipe(filter(q => !!q)),
            companyTeam: this.teamContext.companyTeam$.pipe(filter(ct => !!ct))
        }).pipe(
            switchMap(({ quarter, companyTeam }) => {
                if (!quarter || !companyTeam || !companyTeam.team) return EMPTY;
                this.initLoad();
                return this.planReportsApi.listReports(
                    companyTeam.company.id,
                    companyTeam.team.id,
                    toFiscalQuarter(quarter)
                ).pipe(
                    catchError(this.loadFailed),
                    tap(() => this.isLoading = false),
                    startWith(null),
                );
            }),
        ).subscribe(data => this.dataSubject.next(data)));

        this.subscriptions.add(this.isCommittedControl.valueChanges.subscribe(this.setCommittedStatus));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    refresh = () => {
        this.quarterSubject.next(this.quarterSubject.value);
    };

    addReport = () => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        const quarter = this.quarterSubject.value;
        if (!company || !team || !quarter) return;

        this.checkCapStatus(company, team, quarter).pipe(
            switchMap(() => EditReportDialogComponent.openForAdd(this.dialog, {
                company: company,
                team: team,
                financialYear: quarter.financialYear,
                planningPeriod: quarter.quarter,
            }).afterClosed()),
        ).subscribe(res => res && this.refresh());
    };

    handleReportEvent = (event: ReportEvent) => {
        switch (event.event) {
            case "edit":
                this.editReport(event.report);
                break;
            case "copy":
                this.copyReport(event.report);
                break;
            case "delete":
                this.deleteReport(event.report);
                break;
            case "updated":
                this.refresh();
                break;
        }
    };

    private editReport = (report: GetReportDto) => {
        if (report.planningStatus === PlanningStatus.locked) {
            // Show the warning, but continue to show the read-only dialog
            this.notificationService.warning("reports.editLockWarning", undefined, undefined, true);
        }
        EditReportDialogComponent.openForEdit(this.dialog, report)
            .afterClosed().subscribe(res => res && this.refresh());
    };

    private copyReport = (report: GetReportDto) => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        if (!company || !team || company.id !== report.company.id || team.id !== report.team.id) return;

        this.checkCapStatus(company, team, { financialYear: report.financialYear, quarter: report.planningPeriod })
            .pipe(switchMap(() => EditReportDialogComponent.openForEdit(this.dialog, report, /* isCopy: */ true).afterClosed()))
            .subscribe(res => res && this.refresh());
    };

    private deleteReport = (report: GetReportDto) => {
        if (report.planningStatus === PlanningStatus.locked) {
            this.notificationService.warning("reports.deleteLockWarning", undefined, undefined, true);
            return;
        }
        DeleteReportDialogComponent.open(this.dialog, report)
            .afterClosed().subscribe(res => res ? this.refresh() : null);
    };

    private initLoad = () => {
        this.isLoading = true;
        this.hasFailed = false;
    };

    private loadFailed = () => {
        this.notificationService.errorUnexpected();
        this.hasFailed = true;
        return of(null);
    };

    private setCommittedStatus = (isCommitted: boolean) => {
        const currentData = this.dataSubject.value;
        const quarter = this.quarterSubject.value;
        const companyTeam = this.teamContext.companyTeam();
        if (this.isLoading || !currentData?.reports.length || !quarter || !companyTeam?.team) return;

        this.isLoading = true;
        this.planReportsApi.updateStatus(
            companyTeam.company.id,
            companyTeam.team.id,
            toFiscalQuarter(quarter),
            {
                planningStatus: isCommitted ? PlanningStatus.locked : PlanningStatus.draft
            }
        ).subscribe({
            next: data => {
                this.dataSubject.next(data);
                this.notificationService.success("Quarter Status Changed");
                this.isLoading = false;
            },
            error: () => {
                this.notificationService.errorUnexpected();
                this.isLoading = false;
                this.dataSubject.next(this.dataSubject.value);
            },
        });
    };

    private checkCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto, quarter: IQuarter): Observable<void> =>
        defer(() => of(this.isCheckingCap)).pipe(
            filter(isCheckingCap => !isCheckingCap),
            tap(() => this.isCheckingCap = true),
            switchMap(() => this.getCapStatus(company, team, quarter)),
            tap(() => this.isCheckingCap = false),
            switchMap(this.handleCapStatus),
        );

    private getCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto, quarter: IQuarter): Observable<FeatureCapStatusDto> => {
        const reportCap = company.planTier.featureConstraints.reportCount;
        if (reportCap === null || reportCap === undefined) {
            return of({
                canAdd: true,
                actualCount: 0,
                cap: undefined
            });
        }

        return this.planReportsApi.getCapStatus(
            company.id,
            team.id,
            toFiscalQuarter(quarter)
        ).pipe(catchError(() => of({
            canAdd: true,
            actualCount: 0,
            cap: undefined
        })));
    };

    private handleCapStatus = (capStatus: FeatureCapStatusDto): Observable<void> => {
        if (capStatus.canAdd) return of(undefined);

        ReportCappedDialogComponent.open(this.dialog);
        return EMPTY;
    };
}
