import { Component, HostBinding, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetNumberDto, NumberRecordDetailDto } from "@api";
import { BehaviorSubject, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { ViewNumberCalcSourcesDialogComponent } from "~shared/dialogs";
import { CaptureMethod } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortNumber } from "~shared/util/sorters";

const getRelevantWeek = (number: GetNumberDto | NumberRecordDetailDto): number | null => {
    if ("week" in number) return number.week;
    if (number.latestWeek != null) return number.latestWeek;
    // If latestWeek is null, we may be before the quarter has started. In this case, use the first scheduled week.
    const weeks = Object.keys(number.weekTargets)
        .filter(weekString => Object.prototype.hasOwnProperty.call(number.weekTargets, weekString))
        .map(weekString => parseInt(weekString, 10))
        .sort(sortNumber.ascending());
    // This implies the number is not scheduled for any time in the quarter it exists.
    if (!weeks.length) return null;
    return weeks[0];
};

@Component({
    selector: "app-number-calc-sources-button",
    templateUrl: "./number-calc-sources-button.component.html",
    styleUrls: ["./number-calc-sources-button.component.scss"]
})
export class NumberCalcSourcesButtonComponent extends WithDestroy() {

    @Input() set number(value: GetNumberDto | NumberRecordDetailDto | null) {
        this.numberSubject.next(value);
        this.relevantWeek = !value ? null : getRelevantWeek(value);
    }

    get number(): GetNumberDto | NumberRecordDetailDto | null {
        return this.numberSubject.value;
    }

    get isCalculated(): boolean {
        return !!this.number && this.number.captureMethod === CaptureMethod.calculated;
    }

    get isDailyUpdated(): boolean {
        return !!this.number && !!this.number.dailyUpdateDefinition;
    }

    get canViewDetails(): boolean {
        return this.relevantWeek != null;
    }

    @HostBinding("class.hidden")
    get isHidden(): boolean {
        return !this.isCalculated && !this.isDailyUpdated;
    }

    readonly hasAccess$: Observable<boolean>;

    private numberSubject = new BehaviorSubject<GetNumberDto | NumberRecordDetailDto | null>(null);
    private relevantWeek: number | null = null;

    constructor(
        private readonly accessService: AccessService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.hasAccess$ = this.numberSubject.pipe(
            switchMap(number => {
                if (!number) return of(false);
                const { company, team } = getDelegatedItemCompanyTeam(number);
                return this.accessService.canAccessCompanyTeam(company.id, team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    openDialog = () => {
        const number = this.number;
        const week = this.relevantWeek;
        if (!number || week == null) return;

        ViewNumberCalcSourcesDialogComponent.open(this.dialog, number, week);
    };

}
