import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CurrentCompanyDto, CurrentTeamDto, FeatureCapStatusDto, GetNumberDto, ListNumbersDto, PlanNumbersApi } from "@api";
import { BehaviorSubject, combineLatest, defer, EMPTY, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";

import { NumberCappedDialogComponent } from "~/app/plan-shared/dialogs";
import { IQuarter } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { PlanningStatus } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { sortNumberDefinition } from "~shared/util/number-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

import { INumberEvent } from "../../components";
import { DeleteNumberDialogComponent } from "./delete-number-dialog/delete-number-dialog.component";
import { EditNumberDialogComponent } from "./edit-number-dialog/edit-number-dialog.component";
import { SuggestNumberDialogComponent } from "./suggest-number-dialog/suggest-number-dialog.component";

@Component({
    selector: "app-numbers",
    templateUrl: "./numbers.component.html",
    styleUrls: ["./numbers.component.scss"],
    host: {
        class: "wf-page",
    },
})
export class NumbersComponent extends WithDestroy() implements OnInit, OnDestroy {

    companyId$: Observable<string>;

    readonly quarter$: Observable<IQuarter | null>;

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        if (value) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
        this.isLoadingInternal = value;
    }

    hasFailed = false;

    set quarter(value: IQuarter) {
        this.quarterSubject.next(value);
    }

    get isCommitted(): boolean {
        return this.isCommittedOverride ?? this.planningStatus === PlanningStatus.locked;
    }

    get canEditStatus(): boolean {
        return !!this.numbers && this.numbers.length > 0 && !this.isLoading && !this.hasFailed;
    }

    get suggestionsEnabled() {
        return this.teamContext.features.planningSuggestionsEnabled() ||
            this.userContext.isSuperAdmin() && this.teamContext.features.superAdminPlanningSuggestionsEnabled();
    }

    numbers?: GetNumberDto[];

    readonly hasNumberCap$: Observable<boolean>;
    readonly capStatus$: Observable<FeatureCapStatusDto>;

    private isCommittedOverride: boolean | null = null;
    private planningStatus?: PlanningStatus;
    private isLoadingInternal = false;
    private isCheckingCap = false;

    private quarterSubject = new BehaviorSubject<IQuarter | null>(null);
    private dataSubject = new Subject<ListNumbersDto | null>();

    private subscriptions = new Subscription();

    constructor(
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.companyId$ = this.teamContext.companyTeam$.pipe(
            filter(Boolean),
            map(ct => ct.company.id));

        this.hasNumberCap$ = this.teamContext.companyTeam$.pipe(
            map(ct => ct?.company.planTier.featureConstraints.numberCount),
            map(c => c !== null && c !== undefined),
        );
        this.capStatus$ = combineLatest({
            ct: this.teamContext.companyTeam$,
            quarter: this.quarterSubject,
        }).pipe(
            switchMap(({ ct, quarter }) => {
                if (!ct || !ct.team || !quarter) {
                    return of({
                        canAdd: true,
                        actualCount: 0,
                        cap: undefined
                    });
                }
                return this.getCapStatus(ct.company, ct.team, quarter);
            }),
            shareReplayUntil(this.destroyed$),
        );
        this.quarter$ = this.quarterSubject.pipe(shareReplayUntil(this.destroyed$));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.dataSubject.subscribe(data => {
            this.planningStatus = data?.planningStatus;
            // Note: we exclude any of the daily updated numbers from the list.
            // These cannot be edited directly, but rather the parent must be edited.
            this.numbers = data?.numbers.filter(n => n.updateDay == null).sort(sortNumberDefinition.ascending()) || [];
        }));

        this.subscriptions.add(combineLatest({
            quarter: this.quarterSubject,
            companyTeam: this.teamContext.companyTeam$,
        }).pipe(
            switchMap(({ quarter: qtr, companyTeam: ct }) => {
                if (!qtr || !ct || !ct.team) return EMPTY;
                this.initLoad();
                return this.planNumbersApi.listNumbers(
                    ct.company.id,
                    ct.team.id,
                    toFiscalQuarter(qtr)).pipe(catchError(this.loadFailed));
            }),
            tap(() => this.isLoading = false)
        ).subscribe(
            (data) => {
                this.dataSubject.next(data);
            }
        ));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setCommittedStatus = (isCommitted: boolean) => {
        if (this.isCommittedOverride !== null || !this.canEditStatus) return;

        const companyTeam = this.teamContext.companyTeam();
        const quarter = this.quarterSubject.value;

        if (!companyTeam || !quarter || !companyTeam.team) return;

        this.isCommittedOverride = isCommitted;
        this.isLoading = true;

        this.planNumbersApi.updateStatus(
            companyTeam.company.id,
            companyTeam.team.id,
            toFiscalQuarter(quarter),
            {
                planningStatus: isCommitted ? PlanningStatus.locked : PlanningStatus.draft
            }
        ).subscribe(
            (data) => {
                this.dataSubject.next(data);
                this.notificationService.success("Quarter Status Changed");
                this.isCommittedOverride = null;
                this.isLoading = false;
            },
            () => {
                this.notificationService.errorUnexpected();
                this.isCommittedOverride = null;
                this.isLoading = false;
            }
        );
    };

    refresh = () => {
        const quarter = this.quarterSubject.value;
        if (!quarter) return;
        this.quarterSubject.next(quarter);
    };

    addNumber = () => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        const quarter = this.quarterSubject.value;
        if (!quarter || !company || !team) return;

        this.checkCapStatus(company, team, quarter)
            .pipe(switchMap(() =>
                EditNumberDialogComponent.openForAdd(this.dialog, {
                    company,
                    team,
                    financialYear: quarter.financialYear,
                    planningPeriod: quarter.quarter
                }).afterClosed()))
            .subscribe(res => res ? this.refresh() : null);
    };

    showSuggestions = () => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        const quarter = this.quarterSubject.value;
        if (!quarter || !company || !team) return;
        SuggestNumberDialogComponent.open(this.dialog, {
            company,
            team,
            financialYear: quarter.financialYear,
            planningPeriod: quarter.quarter,
        }).afterClosed().subscribe(() => this.refresh());
    };

    handleNumberEvent = (event: INumberEvent) => {
        switch (event.event) {
            case "edit":
                this.editNumber(event.number);
                break;
            case "copy":
                this.copyNumber(event.number);
                break;
            case "delete":
                this.deleteNumber(event.number);
                break;
            case "attachments":
                break;
            case "updated":
                this.refresh();
                break;
        }
    };

    private initLoad = () => {
        this.isLoading = true;
        this.hasFailed = false;
    };

    private loadFailed = () => {
        this.notificationService.errorUnexpected();
        this.hasFailed = true;
        return of(null);
    };

    private editNumber = (number: GetNumberDto) => {
        if (number.planningStatus === PlanningStatus.locked) {
            // Show the warning, but continue to show the read-only dialog
            this.notificationService.warning("numbers.editLockWarning", undefined, undefined, true);
        }

        EditNumberDialogComponent.openForEdit(this.dialog, number)
            .afterClosed().subscribe(res => res ? this.refresh() : null);
    };

    private deleteNumber = (number: GetNumberDto) => {
        if (number.planningStatus === PlanningStatus.locked) {
            this.notificationService.warning("numbers.deleteLockWarning", undefined, undefined, true);
            return;
        }
        DeleteNumberDialogComponent.open(this.dialog, number)
            .afterClosed().subscribe(res => res ? this.refresh() : null);
    };

    private copyNumber = (number: GetNumberDto) => {
        const companyTeam = this.teamContext.companyTeam();
        const company = companyTeam?.company;
        const team = companyTeam?.team;
        if (!company || !team || company.id !== number.company.id || team.id !== number.team.id) return;

        this.checkCapStatus(company, team, { financialYear: number.financialYear, quarter: number.planningPeriod })
            .pipe(switchMap(() => EditNumberDialogComponent.openForEdit(this.dialog, number, /* isCopy */ true).afterClosed()))
            .subscribe(res => res ? this.refresh() : null);
    };

    private checkCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto, quarter: IQuarter): Observable<void> =>
        defer(() => of(this.isCheckingCap)).pipe(
            filter(isCheckingCap => !isCheckingCap),
            tap(() => this.isCheckingCap = true),
            switchMap(() => this.getCapStatus(company, team, quarter)),
            tap(() => this.isCheckingCap = false),
            switchMap(this.handleCapStatus),
        );

    private getCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto, quarter: IQuarter): Observable<FeatureCapStatusDto> => {
        const numberCap = company.planTier.featureConstraints.numberCount;
        if (numberCap === null || numberCap === undefined) {
            return of({
                canAdd: true,
                actualCount: 0,
                cap: undefined
            });
        }

        return this.planNumbersApi.getCapStatus(
            company.id,
            team.id,
            toFiscalQuarter(quarter)
        ).pipe(catchError(() => of({
            canAdd: true,
            actualCount: 0,
            cap: undefined
        })));
    };

    private handleCapStatus = (capStatus: FeatureCapStatusDto): Observable<void> => {
        if (capStatus.canAdd) return of(undefined);

        NumberCappedDialogComponent.open(this.dialog);
        return EMPTY;
    };
}
