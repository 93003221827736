<div class="ticker_sec top_row">
    <app-quarter-selector [companyId]="companyId$ | async" (selectedQuarterChange)="quarter = $event">
    </app-quarter-selector>
    <span class="qtr_status" [appDisable]="false">
        {{'goals.status' | translate}}:
        <mat-slide-toggle [formControl]="isCommittedControl">
            {{ (isCommittedControl.value ? "Committed" : "Draft") | translate }}
        </mat-slide-toggle>
    </span>
    <app-execution-score-target [selectedQuarter]="quarter$ | async"></app-execution-score-target>
</div>

<div class="header-row">
    <h2 class="main_heading" [translate]="useAucbgMenus ? 'goals.quarterlyTargets' : 'goals.goals'"></h2>
    
    <button type="button" mat-flat-button (click)="addGoal()" class="add-button" color="primary"
        [appDisable]="false">
        <mat-icon>add</mat-icon>
        {{ (useAucbgMenus ? "goals.setupTarget" : "goals.setupGoal") | translate }}
    </button>

    <button type="button" mat-button class="round-button suggest-button" (click)="showSuggestions()" *ngIf="suggestionsEnabled"
        [appDisable]="false">
        <mat-icon>tips_and_updates</mat-icon>
        {{ 'goals.suggest.suggestGoals' | translate }}
    </button>

    <div class="button-bar">
        <button type="button" mat-icon-button (click)="refresh()" color="accent" [title]="'refresh' | translate" [disabled]="isLoading">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>

<app-error-card *ngIf="hasFailed" (retry)="refresh()"
    [message]="'goals.loadFailed' | translate">
</app-error-card>

<div class="wf-page-body" *ngIf="!hasFailed && !!goals">
    <app-goals-table [goals]="goals" (updated)="refresh()">
    </app-goals-table>
</div>