<form [formGroup]="form" (submit)="save()" class="wf-page">
    <div class="ticker_sec top_row">
        <app-quarter-selector [companyId]="companyId$ | async" (selectedQuarterChange)="quarter = $event">
        </app-quarter-selector>
    </div>

    <div class="header-row">
        <h2 class="main_heading" translate="performanceTargets.title"></h2>
    
        <div class="button-bar">
            <button type="button" mat-flat-button color="primary" (click)="copyPrevious()" *ngIf="canCopyPrevious"
                @fadeIn [disabled]="isLoading || isUpdating">
                {{ 'performanceTargets.copyPreviousQuarter' | translate }}
            </button>

            <button type="submit" mat-flat-button color="primary" *ngIf="form.controls.targets"
                [disabled]="form.disabled || isLoading || isUpdating" [appMatVisualDisable]="!form.valid || form.pristine">
                {{ 'Save' | translate }}
            </button>

            <button type="button" mat-icon-button (click)="refresh()" color="accent" [title]="'refresh' | translate" [disabled]="isLoading">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </div>
    
    <app-error-card *ngIf="hasFailed" (retry)="refresh()"
        [message]="'performanceTargets.loadFailed' | translate">
    </app-error-card>
    
    <div class="score-container" *ngIf="!hasFailed && hierarchy">
        <div class="score-grid">
            <div class="score-two-container" *ngIf="hierarchy.children" [style.grid-row-end]="'span ' + hierarchy.children.length"></div>
            <div class="line vertical" style="grid-row-start: 1; grid-column-start: 1;"></div>
            <div class="execution-score">
                <ng-container *ngTemplateOutlet="scoreControl; context: { score: hierarchy }">
                </ng-container>
            </div>
            <ng-container *ngFor="let scoreTwo of hierarchy.children; let k = index; trackBy: trackByLevel">
                <div class="score-two-section" [style.grid-row]="k + 2">
                    <ng-container *ngTemplateOutlet="scoreControl; context: { score: scoreTwo }">
                    </ng-container>
                </div>
                <div class="line horizontal" style="grid-column-start: 1;" [style.grid-row-start]="k + 2"></div>
                <div class="score-three-section" [style.grid-row]="k + 2">
                    <ng-container *ngFor="let scoreThree of scoreTwo.children; let l = index; trackBy: trackByLevel">
                        <ng-container *ngTemplateOutlet="scoreControl; context: { score: scoreThree }">
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <ng-template #scoreControl let-score="score">
            <ng-container *ngIf="asScore(score) as score">
                <mat-form-field floatLabel="always" subscriptSizing="dynamic" *ngIf="form.controls.targets.controls[score.level] as control">
                    <mat-label>
                        <ng-container *ngIf="getScoreLevelNameKey(score.level); let nameKey; else rawName">
                            {{ nameKey | translate }}
                        </ng-container>
                        <ng-template #rawName>
                            {{ score.name }}
                        </ng-template>
                    </mat-label>
                    <input matInput type="number" [formControl]="control" min="0" max="100"
                        [placeholder]="(weightedTargets?.[score.level] | number: '1.0-0') ?? ('executionScoreTarget.none' | translate)" />
                    <span matTextSuffix>%</span>
                </mat-form-field>
            </ng-container>
        </ng-template>
    </div>
</form>

