<div class="ticker_sec top_row">
    <app-quarter-selector [companyId]="companyId$ | async" (selectedQuarterChange)="quarter = $event">
    </app-quarter-selector>
    <span class="qtr_status" [appDisable]="false">
        {{'numbers.status' | translate}}:
        <mat-slide-toggle [ngModel]="isCommitted" (ngModelChange)="setCommittedStatus($event)"
            [disabled]="!canEditStatus">
            {{ (isCommitted ? "Committed" : "Draft") | translate }}
        </mat-slide-toggle>
    </span>
    <app-execution-score-target [selectedQuarter]="quarter$ | async"></app-execution-score-target>
</div>

<div class="header-row">
    <h2 class="main_heading" translate="numbers.numbers"></h2>

    <wf-help-indicator [matTooltip]="'numbers.helpTooltip' | translate"></wf-help-indicator>

    <button type="button" mat-flat-button (click)="addNumber()" class="add-button" color="primary"
        [appDisable]="false">
        <mat-icon>add</mat-icon>
        {{ 'numbers.setupNumber' | translate }}
    </button>
    
    <button type="button" mat-button class="round-button suggest-button" (click)="showSuggestions()" *ngIf="suggestionsEnabled"
        [appDisable]="false">
        <mat-icon>tips_and_updates</mat-icon>
        {{ 'numbers.suggest.suggestNumbers' | translate }}
    </button>

    <wf-feature-cap-display *ngIf="(hasNumberCap$ | async) && (capStatus$ | async); let capStatus"
        [status]="capStatus"></wf-feature-cap-display>

    <div class="button-bar">
        <button type="button" mat-icon-button (click)="refresh()" color="accent" [title]="'refresh' | translate" [disabled]="isLoading">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>

<app-error-card *ngIf="hasFailed" (retry)="refresh()"
    [message]="'numbers.loadFailed' | translate">
</app-error-card>

<div class="wf-page-body" *ngIf="!hasFailed && !!numbers">
    <app-numbers-table [numbers]="numbers" (numberEvent)="handleNumberEvent($event)">
    </app-numbers-table>
</div>