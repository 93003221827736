import { EntityReferenceDetailsDto, EntityType, GetEnterpriseNumberDto, GetNumberDto, NumberRecordDetailDto, NumberSourceDto, NumberTargetDto, SimpleNumberDto } from "@api";

import { NumberType } from "~shared/enums";

import { getIsoDayOfWeekOrder, makeSortDefinition, sortIsoDayOfWeek, sortMultiple, sortString } from "./sorters";

export declare type NumberStatus = "ontarget" | "offtarget" | null;

const valueIsSet = <T>(value: T | undefined | null): value is T => value !== null && value !== undefined;

export const getNumberPrefix = (type: NumberType) => type === NumberType.currency ? "$" : "";
export const getNumberSuffix = (type: NumberType) => type === NumberType.percentage ? "%" : "";

export const hasLowerBound = (target?: NumberTargetDto): boolean => valueIsSet(target) && valueIsSet(target.lowerBound);
export const hasUpperBound = (target?: NumberTargetDto): boolean => valueIsSet(target) && valueIsSet(target.upperBound);

export const targetIsSet = (target: NumberTargetDto | null | undefined): target is NumberTargetDto =>
    valueIsSet(target) && (valueIsSet(target.lowerBound) || valueIsSet(target.upperBound));

export const getNumberTargetStatus = (result: number | null | undefined, target: NumberTargetDto | null | undefined): NumberStatus => {
    if (!valueIsSet(result) || !targetIsSet(target)) return null;

    return (!valueIsSet(target?.lowerBound) || target?.lowerBound <= result) &&
        (!valueIsSet(target?.upperBound) || target?.upperBound >= result) ? "ontarget" : "offtarget";
};

/**
 * Checks if the number type supports adding numbers across multiple weeks.
 * Note: Currently only the percentage type does not support adding numbers.
 */
export const supportsAddingNumbers = (type: NumberType) => type !== NumberType.percentage;

export const getNumberStatusSortOrder = (result: number | null | undefined, target: NumberTargetDto | null | undefined) => {
    // First in order are numbers that have not been updated.
    if (!valueIsSet(result)) return 0;
    // After that, numbers that are off target.
    // Finally, numbers that are either on target, or have been updated but have no target.
    return getNumberTargetStatus(result, target) === "offtarget" ? 1 : 2;
};

declare type NumberDto = GetNumberDto | NumberRecordDetailDto | SimpleNumberDto | GetEnterpriseNumberDto;

export const sortNumberDefinition = makeSortDefinition<NumberDto>(sortMultiple(
    sortString.ascending(n => n.description),
    sortIsoDayOfWeek.ascending(n => n.updateDay),
));

/**
 * Returns a sortable string that is the order a number should appear when sorted by description.
 * This is required as we want to sort numbers by description, and then by the update day of the week.
 * Note: we also sort by ID or source ID here. This is to ensure that daily numbers with the same name are grouped together.
 *
 * @param number The number to sort by.
 */
export const numberDescriptionSortAccessor = (number: GetNumberDto | NumberRecordDetailDto): string => {
    if (number.updateDay == null && !number.dailyUpdateDefinition) return number.description;
    // Add spaces to ensure that the ID/update day is sorted after the description.
    // This is intended to ensure that numbers with similar names are purely sorted according to the description, not the id/week day.
    const separator = "     ";
    return `${number.description}${separator}${number.source?.id ?? number.id}${separator}${getIsoDayOfWeekOrder(number.updateDay)}`;
};

export const getNumberChildren = <TNumber extends GetNumberDto | NumberRecordDetailDto>(number: TNumber): TNumber[] => {
    if (!number.children || !number.children.length) return [];

    const source: NumberSourceDto = {
        id: number.id,
        globalId: number.globalId,
        company: number.company,
        team: number.team,
        financialYear: number.financialYear,
        planningPeriod: number.planningPeriod,
        description: number.description,
        delegation: number.delegation,
        isDelegated: number.isDelegated,
    };

    return number.children.map(child => ({
        ...number,
        ...child,
        source,
        children: undefined,
        dailyUpdateDefinition: undefined,
    }));
};

export const mapSourceToReference = (source: NumberSourceDto): EntityReferenceDetailsDto => ({
    company: source.company,
    team: source.team,
    heading: source.description,
    description: undefined,
    extras: undefined,
    type: EntityType.number,
    id: source.id,
    week: undefined,
});
