import { computed, Signal } from "@angular/core";
import { CurrentCompanyDto } from "@api";

import { isAdvancedCalculationsEnabled, isAnnualPlanningEnabled, isApprovalsEnabled, isCalculatedNumbersEnabled, isCompanyLogoEnabled, isCompanyLogoTeased, isCrossTeamFeaturesEnabled, isCustomMeetingStructureEnabled, isDailyUpdatedNumbersEnabled, isDelegationEnabled, isDiscussionSuggestionsEnabled, isGlobalCreateEnabled, isLeagueEnabled, isMeetingNumberChartsEnabled, isNewsEnabled, isNoteEnforcementEnabled, isNumberApiEnabled, isPerformanceTargetsEnabled, isPersonalWatchlistsEnabled, isPlanningSuggestionsEnabled, isRecurringActionsEnabled, isReportsEnabled, isStatusNotesEnabled, isSuperAdminDiscussionSuggestionsEnabled, isSuperAdminPlanningSuggestionsEnabled, isTeamWatchlistsEnabled, isTriggeredDiscussionsEnabled, isUserPlanChangeEnabled, isWatchlistSharingEnabled, isWorkInstructionEnabled, isXeroIntegrationEnabled } from "~shared/util/feature-helper";

const buildFeatureEnabledCore = (featureFunc: (company: CurrentCompanyDto | null) => boolean, company: Signal<CurrentCompanyDto | null>):
    Signal<boolean> =>
    computed(() => featureFunc(company()));

export class FeatureContext {

    // Simple Feature Flags
    readonly reportsEnabled = buildFeatureEnabledCore(isReportsEnabled, this.company);
    readonly newsEnabled = buildFeatureEnabledCore(isNewsEnabled, this.company);
    readonly globalCreateEnabled = buildFeatureEnabledCore(isGlobalCreateEnabled, this.company);
    readonly userPlanChangeEnabled = buildFeatureEnabledCore(isUserPlanChangeEnabled, this.company);
    readonly statusNotesEnabled = buildFeatureEnabledCore(isStatusNotesEnabled, this.company);
    readonly workInstructionEnabled = buildFeatureEnabledCore(isWorkInstructionEnabled, this.company);

    // Simple Plan Features
    readonly numberApiEnabled = buildFeatureEnabledCore(isNumberApiEnabled, this.company);
    readonly crossTeamFeaturesEnabled = buildFeatureEnabledCore(isCrossTeamFeaturesEnabled, this.company);
    readonly annualPlanningEnabled = buildFeatureEnabledCore(isAnnualPlanningEnabled, this.company);

    readonly companyLogoEnabled = buildFeatureEnabledCore(isCompanyLogoEnabled, this.company);
    readonly companyLogoTeased = buildFeatureEnabledCore(isCompanyLogoTeased, this.company);

    // Compound Features
    readonly recurringActionsEnabled = buildFeatureEnabledCore(isRecurringActionsEnabled, this.company);
    readonly delegationEnabled = buildFeatureEnabledCore(isDelegationEnabled, this.company);
    readonly triggeredDiscussionsEnabled = buildFeatureEnabledCore(isTriggeredDiscussionsEnabled, this.company);
    readonly calculatedNumbersEnabled = buildFeatureEnabledCore(isCalculatedNumbersEnabled, this.company);
    readonly advancedCalculationsEnabled = buildFeatureEnabledCore(isAdvancedCalculationsEnabled, this.company);
    readonly meetingNumberChartsEnabled = buildFeatureEnabledCore(isMeetingNumberChartsEnabled, this.company);
    readonly approvalsEnabled = buildFeatureEnabledCore(isApprovalsEnabled, this.company);
    readonly leagueEnabled = buildFeatureEnabledCore(isLeagueEnabled, this.company);
    readonly performanceTargetsEnabled = buildFeatureEnabledCore(isPerformanceTargetsEnabled, this.company);
    readonly personalWatchlistsEnabled = buildFeatureEnabledCore(isPersonalWatchlistsEnabled, this.company);
    readonly teamWatchlistsEnabled = buildFeatureEnabledCore(isTeamWatchlistsEnabled, this.company);
    readonly watchlistSharingEnabled = buildFeatureEnabledCore(isWatchlistSharingEnabled, this.company);
    readonly planningSuggestionsEnabled = buildFeatureEnabledCore(isPlanningSuggestionsEnabled, this.company);
    readonly superAdminPlanningSuggestionsEnabled = buildFeatureEnabledCore(isSuperAdminPlanningSuggestionsEnabled, this.company);
    readonly discussionSuggestionsEnabled = buildFeatureEnabledCore(isDiscussionSuggestionsEnabled, this.company);
    readonly superAdminDiscussionSuggestionsEnabled = buildFeatureEnabledCore(isSuperAdminDiscussionSuggestionsEnabled, this.company);
    readonly customMeetingStructureEnabled = buildFeatureEnabledCore(isCustomMeetingStructureEnabled, this.company);
    readonly xeroIntegrationEnabled = buildFeatureEnabledCore(isXeroIntegrationEnabled, this.company);
    readonly noteEnforcementEnabled = buildFeatureEnabledCore(isNoteEnforcementEnabled, this.company);
    readonly dailyUpdatedNumbersEnabled = buildFeatureEnabledCore(isDailyUpdatedNumbersEnabled, this.company);

    constructor(
        private readonly company: Signal<CurrentCompanyDto | null>,
    ) { }
}
