<div class="invite-state" [class.invite-complete]="isComplete">
    <ng-container *ngIf="isNotInvited">
        {{ 'invitationButton.notInvited' | translate }}
    </ng-container>
    <ng-container *ngIf="isSent">
        {{ 'invitationButton.invitationSent' | translate }}
    </ng-container>
    <ng-container *ngIf="isExpired">
        {{ 'invitationButton.invitationExpired' | translate }}
    </ng-container>
    <ng-container *ngIf="isComplete">
        {{ 'invitationButton.complete' | translate }}
    </ng-container>
</div>

<button mat-button type="button" *ngIf="!isComplete" (click)="sendInvitation()"
    [appMatVisualDisable]="state === 'sending'" class="invite-button" [color]="state === 'error' ? 'warn' : 'primary'"
    [ngSwitch]="state">
    <ng-container *ngSwitchCase="'sending'">
        {{ 'invitationButton.sending' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
        {{ 'statusButton.success' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
        {{ 'statusButton.error' | translate }}
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="isNotInvited; else resendTpl">
            {{ 'invitationButton.send' | translate}}
        </ng-container>
        <ng-template #resendTpl>
            {{ 'invitationButton.resend' | translate}}
        </ng-template>
    </ng-container>
</button>